<template>
  <div class="device-add">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/dashboard/device' }">设备管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加设备</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <el-row>
        <el-form :model="addForm" :rules="addRules" ref="addRef" label-width="100px" class="add-form">
          <el-form-item label="设备名称" prop="title">
            <el-input v-model="addForm.title"></el-input>
          </el-form-item>
          <el-form-item label="归属病区" prop="address">
            <el-cascader v-model="addForm.belong" placeholder="可直接搜索病区" :options="hospitalAreaData" filterable></el-cascader>
          </el-form-item>
          <el-form-item label="设备图片" prop="img">
            <upload :url="addForm.img" :filed="'img'" :max-num="1" @checked-files="checkedFiles" @del-select="delSelect"></upload>
          </el-form-item>
          <el-form-item label="设备总数" prop="num">
            <el-input-number v-model="addForm.num" controls-position="right" :min="1"></el-input-number>
          </el-form-item>
          <el-form-item label="设备地址" prop="address">
            <el-input v-model="addForm.address"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('addRef')">立即创建</el-button>
            <el-button @click="backUrl">返回</el-button>
          </el-form-item>
        </el-form>
      </el-row>
    </div>
  </div>
</template>

<script>
import Upload from '../common/Upload'
export default {
  name: 'DeviceAdd',
  components: {
    Upload
  },
  data () {
    return {
      hospitalAreaData: [],
      addForm: {
        title: '',
        belong: [],
        img: '',
        num: 1,
        address: ''
      },
      addRules: {
        title: [
          { required: true, message: '请输入设备名称', trigger: 'blur' },
          { min: 2, max: 30, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        belong: [
          { required: true, message: '请选择归属病区', trigger: 'blur' }
        ],
        img: [
          { required: true, message: '请上传设备图片', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入设备摆放地址', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getHospitalArea()
  },
  methods: {
    // 获取医院和病区的联动数据
    async getHospitalArea () {
      const { data: res } = await this.$http.get('/hospital-and-area')
      if (res.meta.status === 200) {
        this.hospitalAreaData = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    backUrl () {
      this.$router.push('/dashboard/device')
    },
    // 提交表单
    async submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post('/device/0', this.addForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            await this.$router.push('/dashboard/device')
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 上传组件返回值
    checkedFiles (data) {
      this.addForm[data.filed] = data.url
    },
    // 删除已选择文件
    delSelect (field, item, pattern) {
      // 单文件删除
      if (pattern === 'single') {
        this.addForm[field] = ''
      } else {
        // 多文件删除
        const checkedIndex = this.addForm[field].findIndex(fileItem => fileItem === item)
        // 如果已包含了该文件则移除文件 和 checked 样式
        this.addForm[field].splice(checkedIndex, 1)
      }
    }
  }
}
</script>

<style scoped>
.page-content{
  padding: 60px;
}
</style>
